<template>
	<div class="content">

		<Block50t050 :components="Block50t050_data1" class="margin3"></Block50t050>
		<Block50t050 :components="Block50t050_data2" class="margin"></Block50t050>

		<section class="fullPageSectionContainer setMenuColor newForm" data-menu-color="light">
			<div class="fullPageSection">
				<div class="fullPageSection--back" style="background-color: #262E39"></div>
				<Feedback :type='2'></Feedback>
			</div>
		</section>
	</div>
</template>

<script>
	import	Block50t050 from '@/components/app/Block50t050'
	export default {
		metaInfo: {
			title: 'Контакты веб-студии | «‎Артрокетс»',
			meta: [
				{ vmid: 'description', name: 'description', content: 'Контактная информация компании Артрокетс. Адрес офиса, телефоны, схема проезда, почта для связи.' },
				{ vmid: 'og:title', property: 'og:title', content: 'Контакты веб-студии | «‎Артрокетс»' },
				{ vmid: 'og:description', property: 'og:description', content: 'Контактная информация компании Артрокетс. Адрес офиса, телефоны, схема проезда, почта для связи.' },
			],
		},
		data: () => ({
			Block50t050_data1: [
				{
					component: 'map'
				}, {
					component: 'imageSlider',
					data: [
						{
							image: require(`@/assets/img/contacts/5.jpg`),
						},{
							image: require(`@/assets/img/contacts/1.jpg`),
						},{
							image: require(`@/assets/img/contacts/2.jpg`),
						},{
							image: require(`@/assets/img/contacts/3.jpg`),
						},{
							image: require(`@/assets/img/contacts/4.jpg`),
						},{
							image: require(`@/assets/img/contacts/6.jpg`),
						}
					]
				}
			],
			Block50t050_data2: [
				{
					component: 'contacts'
				}, {
					component: 'advantages',
					columns: 2,
					sliderInMobile: false,
					data: [
						{
							title: 'info@artrockets.ru',
							text: 'отдел развития и контроля качества',
							link: 'mailto:info@artrockets.ru',
							notRouter: true
						},{
							title: 'sale@artrockets.ru',
							text: 'отдел продаж',
							link: 'mailto:sale@artrockets.ru',
							notRouter: true
						},{
							title: 'support@artrockets.ru',
							text: 'отдел технической поддержки',
							link: 'mailto:support@artrockets.ru',
							notRouter: true
						},{
							title: 'seo@artrockets.ru',
							text: 'отдел SEO',
							link: 'mailto:seo@artrockets.ru',
							notRouter: true
						}
					]
				}
			]
		}),
		name: 'contacts',
		components: {
			Feedback: () => import('@/components/new/Forms/Feedback'),
			Block50t050
		}
	}
</script>
